import React from "react";
// ${
//     errorMsg && errorMsg?.length > 0
//         ? "border-red"
//         : disabled
//         ? " bg-gray-100"
//         : "bg-custom-gradient"
// }

/* @TODO => Fix all the styles @habib610 Fri September 06,2024 */
const TextField = ({
    label,
    value,
    onChange,
    type,
    name,
    helperMsg,
    errorMsg,
    required,
    icon,
    disabled,
    iconChange = () => {},
    errorButton,
    size,
    wrapper,
    inputWrapper = "w-full rounded-md",
    iconWrapperClass = "right-3 flex items-center rounded-lg",
    ...rest
}) => {
    return (
        <div className={`flex flex-col ${size} `}>
            <label
                className="text-gray-secondary font-normal text-sm sm:text-base xl:text-lg mb-0.8 "
                htmlFor={name}
            >
                {label} {required && <span className="text-red">*</span>}
            </label>
            <div
                className={` relative px-px py-px  flex ${wrapper}  ${
                    errorMsg && errorMsg?.length > 0
                        ? "border-red"
                        : disabled
                        ? " bg-gray-100"
                        : "bg-custom-gradient"
                }  rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 text-btn-s`}
            >
                <input
                    className={`border-none w-full ${inputWrapper}  px-1 py-1  lg:py-1.2  
                    font-normal text-sm sm:text-base xl:text-base 2xl:text-xl ${
                        disabled ? "bg-white text-gray-secondary" : "text-gray"
                    }
                    `}
                    value={value}
                    name={name}
                    type={type}
                    onChange={onChange}
                    required={false}
                    disabled={disabled}
                    {...rest}
                />
                {icon && (
                    <div className={`cursor-pointer`}>
                        <span
                            onClick={() => iconChange()}
                            className={` cursor-pointer  ${iconWrapperClass} flex items-center`}
                        >
                            {icon}
                        </span>
                    </div>
                )}
            </div>
            {errorMsg && (
                <>
                    <span className="text-red text-sm xl:text-base flex items-center flex-wrap gap-0.5">
                        {errorMsg}* {errorButton}
                    </span>
                </>
            )}
            {helperMsg && (
                <span className="text-xs text-gray-secondary">{helperMsg}</span>
            )}
        </div>
    );
};

export default TextField;
