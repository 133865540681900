import { motion } from "framer-motion";
import React, { useState } from "react";
import { IoCheckmarkCircleOutline } from "react-icons/io5";
import { Carousel } from "react-responsive-carousel";
import cn from "../../lib/cn";
import { dollarIcon, risk } from "../../resources/js/icons";
import Container from "../../shared/Container";
import Section from "../../shared/Section";
import {
    opacity,
    translateContainer,
    wavyHeadings,
} from "../../shared/animation/constants/landingAnimation";
import SplitText from "../../shared/animation/typography/SplitText";
import H3 from "../../shared/typography/H3";
import P from "../../shared/typography/P";
import SubHeading from "../../shared/typography/SubHeading";
import Button from "../../shared/ui/Button";
import SliderButton from "../../shared/ui/Slider/SliderButton";
const sliderData = {
    heading: "SROT Investment",
    subText:
        "In publishing and graphic design, Lorem ipsum is a placeholder text commonly",
    list: [
        "Up to 20% IRR Percentage",
        "Monthly Payouts",
        "Risk free zero volatility",
        "High Security",
        "Moderate Liquidity",
    ],
};
const cardData = [
    {
        id: 1,
        heading: "Fixed Deposit",
        list: [
            "Up to 20% IRR Percentage",
            "Monthly Payouts",
            "Risk free zero volatility",
            "High Security",
            "Moderate Liquidity",
        ],
    },
    {
        id: 2,
        heading: "Mutual Funds",
        list: [
            "Up to 20% IRR Percentage",
            "Monthly Payouts",
            "Risk free zero volatility",
            "High Security",
            "Moderate Liquidity",
        ],
    },
    {
        id: 3,
        heading: "Bonds",
        list: [
            "Up to 20% IRR Percentage",
            "Monthly Payouts",
            "Risk free zero volatility",
            "High Security",
            "Moderate Liquidity",
        ],
    },
];

const sideEffect = [
    {
        id: 1,
        img: dollarIcon,
        heading: "Returns",
        paragraph:
            "The process of sending back purchased items for a refund, replacement, or exchange.",
    },
    {
        id: 2,
        isLine: true,
    },
    {
        id: 3,
        img: dollarIcon,
        heading: "Returns",
        paragraph:
            "The ease with which assets can be quickly converted into cash without affecting their value.",
    },
    {
        id: 4,
        isLine: true,
    },
    {
        id: 5,
        img: risk,
        heading: "Risk",
        paragraph:
            "Risk is the possibility of experiencing loss or harm due to uncertain events or decisions. ",
    },
];
const BetterOpportunities = () => {
    const [currentSlide, setCurrentSlide] = useState(0);

    // useEffect(() => {
    //     window.addEventListener("resize", () => {});
    //     return () => window.removeEventListener("resize", () => {});
    // }, []);
    const previousSlide = () => {
        if (currentSlide === 0) {
            setCurrentSlide(cardData.length - 1);
        } else {
            setCurrentSlide(currentSlide - 1);
        }
    };
    const nextSlide = () => {
        if (currentSlide === cardData.length - 1) {
            setCurrentSlide(0);
        } else {
            setCurrentSlide(currentSlide + 1);
        }
    };
    return (
        <Section>
            <Container>
                <motion.div
                    initial="initial"
                    whileInView="animate"
                    variants={translateContainer}
                >
                    {" "}
                    <div className="w-full flex flex-col gap-0.8">
                        <SplitText heading="h2" variants={wavyHeadings}>
                            Better than any deal out there !!!
                        </SplitText>
                    </div>
                    <motion.div variants={opacity}>
                        <SubHeading className="mb-5.6">
                            In publishing and graphic design, Lorem ipsum is a
                            placeholder text commonly
                        </SubHeading>
                    </motion.div>
                </motion.div>

                {/* @DESC:: SideEffects cards @habib610 Sat September 14,2024 */}
                <div className="flex justify-between gap-2 mb-5.6">
                    {sideEffect.map((item) =>
                        item.isLine ? (
                            <div
                                key={item.id}
                                className="h-auto w-px bg-blue-300 rounded-2 my-1"
                            ></div>
                        ) : (
                            <div
                                key={item.id}
                                className="flex-1 bg-gray-50 p-1.6 max-w-[400px] rounded-sm"
                            >
                                <div className="flex items-center gap-1.2 mb-1">
                                    <img
                                        src={item.img}
                                        alt={item.heading}
                                        className="h-[40px] "
                                    />
                                    <H3 className="text-3xl">{item.heading}</H3>
                                </div>
                                <P>{item.paragraph}</P>
                            </div>
                        )
                    )}
                </div>

                {/* @DESC:: Cards @habib610 Sat September 14,2024 */}
                <div className="flex  gap-x-4  justify-between items-center gap-y-4">
                    {/* @DESC:: first grid @habib610 Sat September 14,2024 */}
                    <div className="p-2.4 rounded-2.5 bg-linear-blue-gray xl:max-w-[320px] 2xl:max-w-[340px]">
                        <H3 className="text-3xl text-white">
                            {sliderData.heading}
                        </H3>
                        <P className="text-white">{sliderData.subText}</P>
                        <div className="w-full h-px bg-blue-300 my-1.6"></div>

                        {sliderData.list.map((listValue, i) => (
                            <div
                                key={{ listValue }}
                                className={cn(
                                    "flex items-center mb-3.2 gap-0.8",
                                    {
                                        "mb-0":
                                            i === sliderData.list.length - 1,
                                    }
                                )}
                            >
                                <P className="text-xl">
                                    <IoCheckmarkCircleOutline />
                                </P>
                                <P className="text-lg"> {listValue}</P>
                            </div>
                        ))}
                        <Button className="mt-2 w-3/5">Invest Now</Button>
                    </div>

                    <div className="w-full xl:w-[70%] 2xl:w-auto">
                        <Carousel
                            selectedItem={currentSlide}
                            onChange={setCurrentSlide}
                            showArrows={false}
                            showStatus={false}
                            showIndicators={false}
                            infiniteLoop={true}
                            showThumbs={false}
                            interval={3000}
                            autoPlay={true}
                            stopOnHover={true}
                            transitionTime={500}
                            swipeable={true}
                            preventMovementUntilSwipeScrollTolerance={true}
                            swipeScrollTolerance={50}
                            emulateTouch={true}
                            centerMode={true}
                            centerSlidePercentage={
                                document.body.clientWidth < 1279
                                    ? document.body.clientWidth < 1100
                                        ? document.body.clientWidth < 766
                                            ? document.body.clientWidth < 630
                                                ? 100
                                                : 80
                                            : 60
                                        : 46
                                    : document.body.clientWidth > 1535
                                    ? 28
                                    : 33
                            }
                        >
                            {cardData.map((item) => (
                                <div
                                    key={item.id}
                                    className="p-2.4 border border-blue-300 rounded-2.5 mx-1"
                                >
                                    <H3 className="text-3xl ">
                                        {item.heading}
                                    </H3>
                                    <div className="w-full h-px bg-blue-300 my-1.6"></div>

                                    {item.list.map((listValue, i) => (
                                        <div
                                            key={{ listValue }}
                                            className={cn(
                                                "flex items-center mb-3.2 gap-0.8",
                                                {
                                                    "mb-0":
                                                        i ===
                                                        item.list.length - 1,
                                                }
                                            )}
                                        >
                                            <P className="text-xl">
                                                <IoCheckmarkCircleOutline />
                                            </P>
                                            <P className="text-lg text-left">
                                                {listValue}
                                            </P>
                                        </div>
                                    ))}
                                </div>
                            ))}
                        </Carousel>

                        <SliderButton
                            currentSlide={currentSlide}
                            onNext={nextSlide}
                            onPrev={previousSlide}
                            data={cardData}
                        />
                    </div>
                </div>
            </Container>
        </Section>
    );
};

export default BetterOpportunities;
