import React from "react";
import { RiMapPinLine } from "react-icons/ri";
import cn from "../../../lib/cn";
import H4 from "../../../shared/typography/H4";
import P from "../../../shared/typography/P";
import Button from "../../../shared/ui/Button";
import Tag from "../../../shared/ui/tag/Tag";

const ProjectCard = (props) => {
    return (
        <div className="border-5 mx-1 border-white rounded-sm xl:p-1.6 2xl:p-2.4">
            {/* @DESC:: De couple this tag @habib610 Fri September 06,2024 */}
            {/* <div className="text-lg inline-block text-primary bg-primary/10 py-0.6 px-1 rounded-sm">
                {props.tag}
            </div> */}
            <div className="w-full flex justify-start">
                <Tag text={props.tag} />
            </div>

            <P className="text-gray pt-0.8 pb-2.4 text-xl font-sora font-normal text-start">
                {props.headings}
            </P>

            <div className="h-[246px] relative rounded-sm overflow-hidden mb-2.4 ">
                <img
                    className="w-full h-full"
                    src={props.thumbnail}
                    alt="project_image"
                />
                <div className="absolute inset-x-0 inset-y-0 bg-black/30 flex items-end py-1 px-2">
                    <div className="flex justify-between  w-full">
                        <div>
                            <H4 className=" text-white">{props.name}</H4>
                            <P className="text-base flex items-center  gap-0.2 text-white">
                                <RiMapPinLine /> {props.location}
                            </P>
                        </div>
                        <div>
                            <H4 className="text-white font-medium">
                                {props.solarSize}
                            </H4>
                            <P className="text-base text-white">Plant Size</P>
                        </div>
                    </div>
                </div>
            </div>

            {/* @DESC:: progress @habib610 Fri September 06,2024 */}
            <div className="w-full h-[28px] bg-white rounded-sm relative mb-1.6">
                <div
                    className={cn(` h-full rounded-sm bg-linear-yellow-green`)}
                    style={{
                        width: `${props.progress}%`,
                    }}
                ></div>
                <div className="absolute inset-x-0 inset-y-0 flex items-center justify-end pr-4">
                    <P> {props.progress}% reserved</P>
                </div>
            </div>

            <div>
                {props.facilities.map((item) => (
                    <div
                        className="flex justify-between items-center mb-1.6"
                        key={item.id}
                    >
                        <P>{item.name}</P>
                        <P className="font-semibold">{item.value}</P>
                    </div>
                ))}
            </div>

            <Button className="w-full">Invest Now</Button>
        </div>
    );
};

export default ProjectCard;
