import PropTypes from "prop-types";
import React from "react";
import cn from "../../lib/cn";

const baseStyles = "text-gray-secondary font-light text-3xl font-nunito";

const SubHeading = ({ className = "", children, ...rest }) => {
    return (
        <p className={cn(baseStyles, className)} {...rest}>
            {children}
        </p>
    );
};

export default SubHeading;

SubHeading.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
};
