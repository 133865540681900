import React, { useState } from "react";
import { Carousel } from "react-responsive-carousel";
import { solarCard } from "../../resources/js/images";
import Container from "../../shared/Container";
import Section from "../../shared/Section";
import Counter from "../../shared/animation/typography/Counter";
import H2 from "../../shared/typography/H2";
import H3 from "../../shared/typography/H3";
import P from "../../shared/typography/P";
import SubHeading from "../../shared/typography/SubHeading";
import SliderButton from "../../shared/ui/Slider/SliderButton";
import Tab from "../../shared/ui/tab/Tab";
import ProjectCard from "./Sections/ProjectCard";
const projectsCarouselSettings = {
    infinite: true,
    speed: 300,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    dots: true,
    autoplay: true,
    responsive: [
        {
            breakpoint: 1280,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                initialSlide: 2,
                autoplay: true,
                dots: false,
            },
        },
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                initialSlide: 2,
                autoplay: true,
                dots: false,
            },
        },
        {
            breakpoint: 640,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                initialSlide: 2,
                autoplay: true,
                dots: false,
            },
        },

        {
            breakpoint: 425,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                autoplay: true,
                dots: false,
            },
        },

        {
            breakpoint: 360,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                autoplay: true,
                dots: false,
            },
        },
    ],
};
const projects = [
    {
        id: 1,
        name: "Capacity installed",
        unit: "+ MW",
        value: 20,
    },
    {
        id: 2,
        name: "Projects executed",
        unit: "+",
        value: 20,
    },
    {
        id: 3,
        name: "Projects executed",
        unit: "+ Tons",
        value: 480,
    },
    {
        id: 4,
        name: "Communities benefitting",
        unit: "+",
        value: 100,
    },
];
const cardData = [
    {
        id: 1,
        tag: "Telecom Industry",
        headings: "Tata Communications Bangalore",
        name: "Mohar Pratima",
        location: "Whitefield,Banglore",
        solarSize: "15 KWp",
        progress: 43,
        thumbnail: solarCard,
        facilities: [
            {
                id: 1,
                name: "Returns (IRR)",
                value: "19%",
            },
            {
                id: 1,
                name: "Minimum Investment",
                value: "₹5000",
            },
            {
                id: 3,
                name: "Investment term",
                value: "5 years",
            },
        ],
    },
    {
        id: 2,
        tag: "Telecom Industry",
        headings: "Tata Communications Bangalore",
        name: "Mohar Pratima",
        location: "Whitefield,Banglore",
        solarSize: "15 KWp",
        progress: 49,
        thumbnail: solarCard,
        facilities: [
            {
                id: 1,
                name: "Returns (IRR)",
                value: "19%",
            },
            {
                id: 1,
                name: "Minimum Investment",
                value: "₹5000",
            },
            {
                id: 3,
                name: "Investment term",
                value: "5 years",
            },
        ],
    },
    {
        id: 3,
        tag: "Telecom Industry",
        headings: "Tata Communications Bangalore",
        name: "Mohar Pratima",
        location: "Whitefield,Banglore",
        solarSize: "15 KWp",
        progress: 20,
        thumbnail: solarCard,
        facilities: [
            {
                id: 1,
                name: "Returns (IRR)",
                value: "19%",
            },
            {
                id: 1,
                name: "Minimum Investment",
                value: "₹5000",
            },
            {
                id: 3,
                name: "Investment term",
                value: "5 years",
            },
        ],
    },
];

const tags = ["Active", "Upcoming", "Completed"];
const SolarProjects = () => {
    const [active, setActive] = useState(tags[0]);
    const [currentSlide, setCurrentSlide] = useState(0);

    // useEffect(() => {
    //     window.addEventListener("resize", () => {});
    //     return () => window.removeEventListener("resize", () => {});
    // }, []);
    const previousSlide = () => {
        if (currentSlide === 0) {
            setCurrentSlide(cardData.length - 1);
        } else {
            setCurrentSlide(currentSlide - 1);
        }
    };

    const nextSlide = () => {
        if (currentSlide === cardData.length - 1) {
            setCurrentSlide(0);
        } else {
            setCurrentSlide(currentSlide + 1);
        }
    };

    return (
        /* @TODO => fix arbitrary values @habib610 Fri September 06,2024 */
        <Section className="pt-[113px] 2xl:mb-0 bg-gray-50 pb-4">
            <Container>
                <H2 className="mb-1.6 text-gray">
                    Making a Difference, One Project at a Time
                </H2>
                <SubHeading className=" mb-5.6">
                    Maximize your investment potential with our solar projects.
                </SubHeading>{" "}
                {/* @DESC:: counter @habib610 Fri September 06,2024 */}
                <div className="grid grid-cols-4 mb-5.6 ">
                    {projects.map((item, i) => (
                        <div className={`flex ${i === 3 && "justify-end"}`}>
                            <div className="mr-8.8">
                                <H3 className="font-sora">
                                    <Counter value={item.value} />
                                    {item.unit}
                                </H3>
                                <P>{item.name}</P>
                            </div>
                            {i !== 3 && (
                                <div className="h-full w-px bg-blue-300 rounded-full"></div>
                            )}
                        </div>
                    ))}
                </div>
                {/* @TODO => Tags here @habib610 Fri September 06,2024 */}
                <div className="flex flex-nowrap overflow-x-auto w-[80%] xl:w-[75%] gap-2.4 mb-2.4 no-scrollbar">
                    {tags.map((tag, i) => (
                        <Tab
                            key={i}
                            text={tag}
                            active={tag === active}
                            onClick={() => setActive(tag)}
                        />
                    ))}
                </div>
                {/* @DESC:: slider @habib610 Fri September 06,2024 */}
                <div className="">
                    <Carousel
                        selectedItem={currentSlide}
                        onChange={setCurrentSlide}
                        showArrows={false}
                        showStatus={false}
                        showIndicators={false}
                        infiniteLoop={true}
                        showThumbs={false}
                        interval={3000}
                        autoPlay={true}
                        stopOnHover={true}
                        transitionTime={500}
                        swipeable={true}
                        preventMovementUntilSwipeScrollTolerance={true}
                        swipeScrollTolerance={50}
                        emulateTouch={true}
                        centerMode={true}
                        centerSlidePercentage={
                            document.body.clientWidth < 1279
                                ? document.body.clientWidth < 1100
                                    ? document.body.clientWidth < 766
                                        ? document.body.clientWidth < 630
                                            ? 100
                                            : 80
                                        : 60
                                    : 46
                                : document.body.clientWidth > 1535
                                ? 28
                                : 33
                        }
                    >
                        {cardData.map((card) => (
                            <ProjectCard key={card.id} {...card} />
                        ))}
                    </Carousel>
                    <SliderButton
                        currentSlide={currentSlide}
                        onNext={nextSlide}
                        onPrev={previousSlide}
                        data={cardData}
                    />
                </div>
            </Container>
        </Section>
    );
};

export default SolarProjects;
