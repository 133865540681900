import PropTypes from "prop-types";
import React from "react";
import cn from "../../lib/cn";
const baseStyles = "text-gray font-nunito text-xl font-sora ";

const H4 = ({ className = "", children, ...rest }) => {
    return (
        <h4 {...rest} className={cn(baseStyles, className)}>
            {children}
        </h4>
    );
};

export default H4;

H4.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
};
