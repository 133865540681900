import PropTypes from "prop-types";
import React from "react";
import { twMerge } from "tailwind-merge";
const baseStyles = "font-regular text-4.0xl text-gray font-nunito";

const H3 = ({ className = "", children }) => {
    return <h3 className={twMerge(baseStyles, className)}>{children}</h3>;
};

export default H3;

H3.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
};
