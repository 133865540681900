import React from "react";
import cn from "../../../lib/cn";

const Tag = ({ text, className, textClass }) => {
    return (
        <div
            className={cn(
                `inline-block  py-0.5 px-1   rounded-0.7  bg-blue-200 text-primary `,
                className
            )}
        >
            <span
                className={cn(
                    "text-sm  xl:text-base 2xl:text-btn-s font-regular px-1.2 py-0.5",
                    textClass
                )}
            >
                {text}
            </span>
        </div>
    );
};

export default Tag;
