import { motion } from "framer-motion";
import React from "react";
import { startFilled } from "../../resources/js/icons";
import { slideOpacityUp } from "../../shared/animation/constants/landingAnimation";
import H4 from "../../shared/typography/H4";
import P from "../../shared/typography/P";
const ReviewItem = ({ data }) => {
    return (
        <motion.div
            variants={slideOpacityUp}
            className=" snap-center mx-2 xl:mx-0 sm:snap-none h-auto bg-gray-150 border-002 border-blue-300 rounded-m  p-1.6"
        >
            <div className="flex justify-between gap-2 items-start">
                <div>
                    <H4>{data.name}</H4>
                </div>
                <div>
                    <p className="text-gray-secondary font-normal text-xs">
                        {data.date}
                    </p>
                    <div className="flex items-center gap-2 mt-0.5">
                        <img
                            className="h-1.8"
                            src={startFilled}
                            alt="review_stars"
                        />
                        <p className=" text-gray-secondary text-lg ">
                            {data.rate}
                        </p>
                    </div>
                </div>
            </div>
            <div className="h-0.1 w-full bg-gray-100 mt-0.5 mb-1"></div>
            <div>
                <P className="text-xs sm:text-base xl:text-sm 2xl:text-sm text-gray-secondary ">
                    {data.comment}
                </P>
            </div>
        </motion.div>
    );
};

export default ReviewItem;
