import { ErrorBoundary } from "react-error-boundary";
import ReactGA from "react-ga";
import { BrowserRouter } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import styled, { ThemeProvider } from "styled-components";
import Routes from "./Routes";
import BottomTab from "./components/bottom-tabs";
import ErrorFallback from "./components/error/ErrorFallback";
// import Header from "./components/header/index";
import RequestHandler from "./components/requestHandler/RequestHandler";
import WebSocketConfig from "./configuration/WebSocketConfig";
import LoginModal from "./login/LoginModal";
import SignupModal from "./login/SignupModal";
import Footer from "./shared/footer/Footer";
import Header from "./shared/header/Header";
import theme from "./theme";
import GlobalStyles from "./theme/GlobalStyles";
ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_CODE);

const App = () => {
    return (
        // <CookiesProvider>
        <ThemeProvider theme={theme}>
            <GlobalStyles />
            <BrowserRouter>
                <ErrorBoundary FallbackComponent={ErrorFallback}>
                    <RequestHandler />
                    <Header />
                    <WebSocketConfig />
                    <Wrapper>
                        <main>
                            <Routes />
                        </main>

                        <Footer />
                    </Wrapper>

                    <BottomTab />
                    <LoginModal />
                    <SignupModal />
                </ErrorBoundary>
            </BrowserRouter>
        </ThemeProvider>
        // </CookiesProvider>
    );
};

export default App;

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    min-height: 100vh;
    main {
        flex: 1;
    }
`;
