import {
    BsWallet2,
    FiGrid,
    RiFolderChartLine,
} from "../components/bottom-tabs/TabIcons";
export const isProdEnv = () => {
    if (process.env.REACT_APP_ENV === "production") return true;
    else return false;
};

export const LANDING = "/"; // delete this
export const HOME = "/";
export const LOGIN = "/login";
export const SINGUP = "/signup";
export const PROFILE = "/profile";
export const REFERRAL = "/referral";
export const FAQ = "/faq";
export const OPPORTUNITY = "/opportunity";
export const OPPORTUNITYDETAILS = "/opportunity/:id";
export const COUPON = "/coupon";
export const WALLET = "/wallet";
export const CONTACTUS = "/contact-us";
export const PORTFOLIO = "/portfolio";
export const TERMS_N_CONDITIONS = "/terms-n-condition";
export const PRIVACY = "/privacy";
export const FORGET = "/forget-password";
export const RESET_SCREEN = "/reset";
export const VERIFY_EMAIL = "/verify_email";
export const ERROR_SCREEN = "/error";
export const HOW_TO_SCREEN = "/how-to";
export const NOT_FOUND = "/404";
export const THANK_YOU = "/thankyou";
export const OFFSET = "/offset";
export const PROJECTS = "/projects";

export const LOGIN_LABEL = "Login";
export const FAQ_LABEL = "FAQs";
export const CONTACTUS_LABEL = "Contact Us";
export const OPPORTUNITY_LABEL = "Opportunity";
export const PORTFOLIO_LABEL = "Portfolio";
export const REFERRAL_LABEL = "Referral";
export const WALLET_LABEL = "Wallet";
export const HOW_TO_LABEL = "How To";
export const OFFSET_LABEL = "Impact";
export const PROJECTS_LABEL = "Projects";
export const PROFILE_LABEL = "Profile";

export const REF_CODE = "LAUNCH";
export const REF_URL = isProdEnv()
    ? "https://srot.app/"
    : "https://test.srot.app/";

export const burgerNavLinks = [
    {
        name: CONTACTUS_LABEL,
        path: CONTACTUS,
    },
    {
        name: FAQ_LABEL,
        path: FAQ,
    },
    {
        name: OFFSET_LABEL,
        path: OFFSET,
    },
    {
        name: PROJECTS_LABEL,
        path: PROJECTS,
    },
];

export const navLinks = [
    {
        name: CONTACTUS_LABEL,
        path: CONTACTUS,
    },
    {
        name: FAQ_LABEL,
        path: FAQ,
    },
    {
        name: OFFSET_LABEL,
        path: OFFSET,
    },
    {
        name: PROJECTS_LABEL,
        path: PROJECTS,
    },
];

export const authNavLinks = [
    {
        name: CONTACTUS_LABEL,
        path: CONTACTUS,
    },
    {
        name: FAQ_LABEL,
        path: FAQ,
    },

    {
        name: WALLET_LABEL,
        path: WALLET,
    },
    {
        name: OFFSET_LABEL,
        path: OFFSET,
    },
    {
        name: PROJECTS_LABEL,
        path: PROJECTS,
    },
    {
        name: PORTFOLIO_LABEL,
        path: PORTFOLIO,
    },
];
export const authBurgerNavLinks = [
    {
        name: PROFILE_LABEL,
        path: PROFILE,
    },
    {
        name: FAQ_LABEL,
        path: FAQ,
    },

    {
        name: WALLET_LABEL,
        path: WALLET,
    },
    {
        name: OFFSET_LABEL,
        path: OFFSET,
    },
    {
        name: PROJECTS_LABEL,
        path: PROJECTS,
    },
    {
        name: PORTFOLIO_LABEL,
        path: PORTFOLIO,
    },
];

export const tabAuthNavLinks = [
    {
        name: PROJECTS_LABEL,
        path: PROJECTS,
        icon: FiGrid,
    },
    {
        name: PORTFOLIO_LABEL,
        path: PORTFOLIO,
        icon: RiFolderChartLine,
    },

    {
        name: WALLET_LABEL,
        path: WALLET,
        icon: BsWallet2,
    },
];

export const API_ENDPOINT = isProdEnv()
    ? process.env.REACT_APP_SROT_URI_PROD
    : process.env.REACT_APP_SROT_URI_DEV;
export const SIGNUP_ENDPOINT = "/signup/investor";
export const EMAIL_VERIFICATION_CODE_ENDPOINT =
    "/investor/generate_email_verification_code";
export const VERIFY_EMAIL_ENDPOINT = "/signup/verify_email";
export const LOGIN_ENDPOINT = "/login";
export const LOGOUT_ENDPOINT = "/logout";
export const LOGIN_OTP_ENDPOINT = "/login/otp";
export const REFRESH_TOKEN_ENDPOINT = "/login/refreshToken";
export const CHECK_WALLET_BALANCE_ENDPOINT = "/investor/wallet/getWallet";
export const QR_CODE_ENDPOINT = "https://upiqr.in/api/qr";
export const UPDATE_PAYMENT_ORDER_ENDPOINT = "/investor/wallet/updateOrder";
export const WITHDRAW_WALLET_ENDPOINT = "/investor/wallet/withdraw";
export const UPDATE_EMAIL_ENDPOINT = "/investor/update_email";
export const CREATE_ORDER_ENDPOINT = "/investor/wallet/createOrder";
export const COUPON_API_ENDPOINT = "/investor/questionnaire";
export const PROFILE_API_ENDPOINT = "/investor/profile";
export const OPPORTUNITY_ENDPOINT = "/assets";
export const OPPORTUNITY_DETAILS_ENDPOINT = "/assets/details";
export const VIRTUAL_INTEREST_CANCEL_ENDPOINT = "/investor/virtual/cancel";

export const GENERATE_DOC_ENDPOINT = "/investment/invest/generateDoc";
export const VERIFY_GENERATE_DOC = "/investment/invest/generateDoc/verify";
export const INVESTOR_FUND_STATUS_ENDPOINT = "/assets/asset/fund/status";
export const SIGN_INVESTMENT_ENDPOINT = "/sign/invest";
export const REFERRAL_ENDPOINT = "/referral";
export const CHANGE_PASSWORD_ENDPOINT = "/investor/change_password";
export const FORGET_PASSWORD_ENDPOINT = "/investor/forget_password";
export const DOCUMENT_UPLOAD_ENDPOINT = "/investor/upload/documents";
export const BANK_VERIFICATION_ENDPOINT = "/investor/profile/bank_kyc";

export const INVESTOR_OPPORTUNITY_ENDPOINT = "/investor/opportunity";
export const SIGNUP_OPPORTUNITY_ENDPOINT = "/signup/opportunity";

export const GET_PAYMENT_METHOD_ENDPOINT =
    "/investor/wallet/supportedPGMethods";
export const UPDATE_PROFILE_ENDPOINT = "/investor/update";
export const PORTFOLIO_ENDPOINT = "/investor/portfolio";
export const PORTFOLIO_INVESTMENT_ID = "/investment";
export const CALLBACK_ENDPOINT = "/investor/profile/callback";
export const RESEND_OTP_ENDPOINT = "/otp/resend_otp";
export const SIGNUP_VIRTUAL_ENDPOINT = "/signup/virtual";
export const RAZORPAY_LOGO_ENDPOINT =
    "https://cdn.razorpay.com/logos/I7e2KpwjikUoNR_medium.png";
export const SIGN_DOCUMENT_DOWNLOAD_ENDPOINT = "/sign/document";

export const RAZOR_SCRIPT_END_POINT = isProdEnv()
    ? process.env.REACT_APP_RAZOR_SCRIPT_URI_PROD
    : process.env.REACT_APP_RAZOR_SCRIPT_URI_DEV;

export const RAZOR_PAY_KEY = isProdEnv()
    ? process.env.REACT_APP_RAZOR_PAY_KEY_PROD
    : process.env.REACT_APP_RAZOR_PAY_KEY_DEV;

export const DIGIO_SCRIPT_END_POINT = `${
    isProdEnv()
        ? process.env.REACT_APP_DIGIO_BASE_URL_PROD
        : process.env.REACT_APP_DIGIO_BASE_URL_DEV
}/sdk/v9/digio.js`;

export const WHATSAPP_SDK_END_POINT = `${
    isProdEnv()
        ? process.env.REACT_APP_WHATS_APP_SDK_URI_PROD
        : process.env.REACT_APP_WHATS_APP_SDK_URI_DEV
}${new Date().getTime()}`;
export const WHATSAPP_SDK_SECRET = isProdEnv()
    ? process.env.REACT_APP_WHATS_APP_SDK_SECRET_PROD
    : process.env.REACT_APP_WHATS_APP_SDK_SECRET_DEV;

/* @DESC::  New variables  */
export const DEFAULT_REF_CODE = isProdEnv()
    ? process.env.REACT_APP_REFERRAL_CODE_PROD
    : process.env.REACT_APP_REFERRAL_CODE_DEV;

/* @DESC::  New endpoint for virtual-srot  */
export const VIRTUAL_DATA_ENDPOINT = "/signup/virtual";
export const QUICK_SIGNUP_ENDPOINT = "/signup/investor/quick";
export const QUICK_VIRTUAL_SIGNUP_ENDPOINT = "/signup/virtual/quick";
export const VALIDATE_API_ENDPOINT = "/signup/validate";
export const INVESTOR_VIRTUAL_API_ENDPOINT = "/investor/virtual";
export const WAITING_LIST_STATUS_API_ENDPOINT = "/waitinglist/status";
export const WAITING_LIST_JOIN_API_ENDPOINT = "/waitinglist/join/new";

export const LEASING_AGREEMENT_PDF = "/docs/Investor_Leasing_Agreement.pdf";

export const WEB_SOCKET_PIPELINE_ENDPOINT = "/secured/public/ws";
export const SOCKET_USER_CONNECTION_ENDPOINT =
    "/secured/public/topic/connectivity";
export const RECENT_NOTIFICATION_QUEUE_ENDPOINT = "/public/notification/recent";
export const SOCKET_PROJECT_NOTIFICATION_ENDPOINT =
    "/secured/public/topic/subscription";

/* @DESC:: New endpoints Sat June 15,2024 */

export const INVESTOR_CREATE_ORDER_WALLET_ENDPOINT = "/investment/createOrder";

export const MAKE_INVESTMENT_API_ENDPOINT = "/investment/invest";
export const ENQUIRY_CONTACT_API_ENDPOINT = "/contactus/ask";
