import PropTypes from "prop-types";
import React from "react";
import cn from "../../lib/cn";
/* @TODO => Fix base @habib610 Fri September 06,2024 */
const baseStyles = "text-gray font-extralight text-7xl font-sora";

const H1 = ({ className = "", children, ...rest }) => {
    return (
        <h1 {...rest} className={cn(baseStyles, className)}>
            {children}
        </h1>
    );
};

export default H1;

H1.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
};
