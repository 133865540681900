import React, { forwardRef } from "react";
import withClickOutside from "../../../hoc/withClickOutside";
import cn from "../../../lib/cn";
import P from "../../typography/P";

const Dropdown = forwardRef(
    (
        {
            selected,
            onSelect,
            isSelected,
            setIsSelected,
            icon,
            data,
            wrapperClass = "bg-gray-100 border-primary",
            disabled = false,
        },
        ref
    ) => {
        const handleSelect = (value) => {
            onSelect(value);
            setIsSelected(false);
        };

        return (
            <div
                ref={ref}
                className={`relative p-1 py-1 lg:py-1.2 ${
                    disabled ? "bg-gray" : "bg-white border border-blue-300"
                } hover:cursor-pointer z-50 rounded-lg`}
                role="button"
                onClick={() => {
                    setIsSelected((prev) => !prev);
                }}
            >
                <div className="flex justify-between gap-1 items-center">
                    <P className="flex-1">{selected}</P>
                    {!disabled && <div className="text-xl">{icon}</div>}
                </div>

                {!disabled && (
                    <div
                        className={cn(
                            "options absolute start-0 end-0 top-[45px] z-30 shadow-md transition-all duration-300",
                            wrapperClass,
                            {
                                "max-h-[500px] opacity-100 overflow-auto":
                                    isSelected,
                                "max-h-0 opacity-0 overflow-hidden":
                                    !isSelected,
                            }
                        )}
                    >
                        {data.map((item, i) => (
                            <P
                                key={i}
                                role="button"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setIsSelected(!isSelected);
                                    return handleSelect(item);
                                }}
                                className="px-1 py-0.5 border-b border-blue-300 text-lsm hover:bg-blue-300 transition-all duration-200 hover:cursor-pointer"
                            >
                                {item}
                            </P>
                        ))}
                    </div>
                )}
            </div>
        );
    }
);

export default withClickOutside(Dropdown);
