import React from "react";
import { HomeBg } from "../../resources/js/images";
import Container from "../../shared/Container";
import H1 from "../../shared/typography/H1";
import P from "../../shared/typography/P";
import BetterOpportunities from "./BetterOpportunities";
import ContactUSForm from "./ContactUSForm";
import CustomerReviews from "./CustomerReviews";
import EstimateSavings from "./EstimateSavings";
import Faq from "./Faq";
import Hero from "./Hero";
import InsightCarouselSection from "./Sections/InsightCarouselSection";
import SolarProjects from "./SolarProjects";
import SrotBenefit from "./SrotBenefit";

const Home = () => {
    return (
        <>
            <Hero
                containerClass={"mb-0 xl:mb-0 lg:mb-0 2xl:mb-0"}
                wrapperClass="bg-gray"
                content={
                    <Container className="text-center w-[80%] xl:w-[70%] 2xl:w-[50%]">
                        <H1 className={"text-white mb-3.2"}>
                            Made for your savvy Savings with Smart Idea
                        </H1>
                        <P className="text-white text-xl font-nunito font-light">
                            SROT is an exclusive community that empowers the
                            reliable to achieve financial growth.
                        </P>
                    </Container>
                }
                url={HomeBg}
            />
            <SrotBenefit></SrotBenefit>
            <SolarProjects />
            <EstimateSavings />
            <BetterOpportunities />
            <CustomerReviews />
            <ContactUSForm />
            <InsightCarouselSection />
            <Faq />
        </>
    );
};

export default Home;
